import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./Footer.scss";
import fbIcon from "../../Assets/Images/navbar/facebook-icon.svg";
import instaIcon from "../../Assets/Images/navbar/instagram-icon.svg";
import linkedinIcon from "../../Assets/Images/navbar/linkedIn-icon.svg";
import twIcon from "../../Assets/Images/navbar/twitter-icon.svg";
import { SOCIALMEDIA_LINKS } from "../../Assets/Constant";
const mapStateToProps = ({ categories, auth }) => ({
  categories,
  auth,
});

const Footer = () => {
  //   const { auth } = useSelector(mapStateToProps);
  //   const { showLoginPopup = false, isLoggedIn, userDetails = {} } = auth;
  const current_year = new Date().getFullYear();
  const {
    categories: { list: categoryList = [] },
    auth,
  } = useSelector(mapStateToProps);
  const { isLoggedIn } = auth;

  const formattedCategories = categoryList;

  const FooterCategoryList = () => {
    return (

      <div className="footer-categories">

        <p style={{
          fontSize: '1.2rem',
          fontWeight: '500',
        }}>Categories</p>

        <div className="footer-categories-contents">

          <ul>
            {
              formattedCategories && formattedCategories.length > 0 ?
                (
                  <>
                    {
                      formattedCategories.map((category, index) => {
                        if (index <= formattedCategories.length / 2) {
                          return (
                            <li>
                              <Link
                                to={`/categories/${category.name}`}
                                key={`category.name`}
                              >
                                {category.name}
                              </Link>
                            </li>
                          );
                        }
                      })

                    }
                  </>
                ) :
                null
            }
          </ul>


          <ul>
            {
              formattedCategories && formattedCategories.length > 0 ?
                (
                  <>
                    {formattedCategories.map((category, index) => {
                      if (index > formattedCategories.length / 2) {
                        return (
                          <li>
                            <Link
                              to={`/categories/${category.name}`}
                              key={`category.name`}
                            >
                              {category.name}
                            </Link>
                          </li>
                        );
                      }
                    })}
                  </>
                )
                :
              null
            }
          </ul>

        </div>
      </div>
    )
  }

  const FooterNavLinks = () => {
    const {auth} = useSelector(mapStateToProps)
    return (
      <ul>
        <li></li>
        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/"
          >
            Home
          </a>
        </li>

        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/"
          >
            Categories
          </a>
        </li>
        {
          auth.tenantDetails?.template_details?.about_us &&
          <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/privacy-policy#Aboutus"
          >
            About Us
          </a>
          </li>
        }
        


       { 
       auth.tenantDetails?.template_details?.return_policy &&
       <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/privacy-policy#ReturnPolicy"
          >
            Return Policy
          </a>
        </li>
        }

        {
          auth.tenantDetails?.template_details?.refund_policy &&
          <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/privacy-policy#RefundPolicy"
          >
            Refund Policy
          </a>
        </li>
        }

        {
          auth.tenantDetails?.template_details?.privacy_policy &&
          <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/privacy-policy#PrivacyPolicy"
          >
            Privacy Policy
          </a>
        </li>
        }
        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/privacy-policy#ContactUs"
          >
            Contact Us
          </a>
        </li>

        <li></li>
      </ul>
    )
  }

  const FooterSocialMediaLink = () => {
    return (
      <ul>
        {/* <li>
                  <p className="footer-head">Contact</p>
                </li> */}
        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href={SOCIALMEDIA_LINKS['FACEBOOK']}
          >
            <img className="social" src={fbIcon} alt="" />
          </a>
        </li>

        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href={SOCIALMEDIA_LINKS['TWITTER']}
          >
            <img className="social" src={twIcon} alt="" />
          </a>
        </li>

        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href={SOCIALMEDIA_LINKS['INSTAGRAM']}
          >
            <img className="social" src={instaIcon} alt="" />
          </a>
        </li>
        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href={SOCIALMEDIA_LINKS['LINKEDIN']}
          >
            <img className="social" src={linkedinIcon} alt="" />
          </a>
        </li>
        <li>
          <a href="mailto:ziyuzabi@gmail.com">
          </a>
        </li>
      </ul>
    )

  }

  return (
    <div className="footer-container">
      <div className="footer-wrapper">

        <div className="footer-logo-navlinks">
          <div style={{

            transform : 'translateY(-5px)'
          }}>
            <p style={{
              fontSize: '2rem',
              whiteSpace: 'nowrap',
              color: '#4a1d1e',
              fontWeight: '500',
              textTransform : 'uppercase'
            }}>
              {auth.tenantDetails?.title}
            </p>

            <p style={{
              fontSize: '1rem',
              width : '13rem',
            }}>
              {
                auth.tenantDetails?.template_details?.footer?.description
              }
            </p>
          </div>

          <div>
            <p style={{
              fontSize: '1.2rem',
              fontWeight: '500',
            }}>Useful Links</p>
            <FooterNavLinks />
          </div>

          {
            true && isLoggedIn && 
            <FooterCategoryList />
          }
        </div>

        <div style={{ width: '100%', height: '1px', backgroundColor: '#4a1a1d' }}></div>



        <div className="footer-sociallinks">
          <p style={{ color: '#272727', fontSize: '1.2rem' }}>© {current_year} {auth.tenantDetails?.title} All rights reserved.</p>
          <FooterSocialMediaLink />
        </div>
      </div>
    </div>
  );
};

export default Footer;