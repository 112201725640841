// import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { productAPI } from "../../Api";
import { ProductCard } from "../../Components";
import { actionsCreator } from "../../Redux/actions/actionsCreator";
import "./Category.scss";
// import { toast } from "react-toastify";
// import get from "lodash/get";
import { CategoryCard } from "../../Components";
import SubCategoryCard from './SubCategory/SubCategoryCard'
import InfiniteScroll from 'react-infinite-scroller';
import LoadingProducts from "../../Components/Loader/LoadingProducts";

const mapStateToProps = ({ auth = {}, categories = {} }) => ({
  auth,
  categories,
});

const Category = () => {
  const {
    auth = {},
    categories: { list: categoryList },
  } = useSelector(mapStateToProps);

  const { isLoggedIn } = auth;
  const [categoryName, setCategory] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [categoryId, setCategoryId] = useState(null)
  const [subCategoryName, setSubCategoryName] = useState("");
  const [subCategoryId, setSubcategoryId] = useState(null)
  const [productsList, setProductsList] = useState(null);
  const { category = "" } = useParams();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showLoadingCards, SetShowLoadingCards] = useState(false)
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadingMore, setLoadingMore] = useState(false);

 
  let color_generator = 0;

  const subtabhandler = (subcategory, id) => {
    // history.replace(subcategory);
    setSubCategoryName(subcategory)
    setSubcategoryId(id)
  };

  const tabhandler = (category) => {
    history.replace(category);
    setCategory(category)
  };

  useEffect(() => {
    categoryList
      && categoryList.map((item) => {
        if (item.name === categoryName) {
          let subcategory_all = {
            id: item.id,
            image: item.image,
            name: item.name,
          }

          // let all_category = item.sub_categories.unshift(subcategory_all)

          if (item.sub_categories.length > 0) {
            setSubCategoryName(item.name)
            setSubcategoryId(item.id)
            setCategoryId(null)
          }
          else {
            setCategory(categoryName);
            setCategoryDescription(item.description)
            setSubCategoryName(categoryName)
            setCategoryId(item.id)
            setSubcategoryId(item.id)
          }
        }
        return 0
      })

  }, [categoryName]);

  useEffect(() => {
    setHasMore(true);
    setPage(1);
    if (subCategoryName && subCategoryId && page) {
      setProductsList([]);
      fetchProducts(1);
    }
    setSubCategoryName(subCategoryName);
  }, [subCategoryName]);

  useEffect(() => {
    if (categoryList && categoryList.length > 0) {

      if (category) {
        setCategory(category);

        const descOfCategory = categoryList.filter(item => {
          return (
            item.name === category
          )
        })

//        setCategoryDescription(descOfCategory[0].desc);

      } else {
        let firstCategory = categoryList[0]["name"];
        let firstCategoryDesc = categoryList[0]["description"]
        setCategory(firstCategory);
        setCategoryDescription(firstCategoryDesc);
        setCategoryId(categoryList[0]["id"])
      }
    }
  }, [categoryList]);

  const fetchCategories = async () => {
    dispatch(actionsCreator.FETCH_CATEGORIES());
  };

  const isMounted = useRef(true);

  useEffect(() => {
    // Cleanup function to set isMounted to false when the component unmounts
    return () => {
      isMounted.current = false;
    };
  }, []);

  const loadMore = () => {
    if (!loadingMore && isMounted.current) {
      setLoadingMore(true);  // Set loadingMore to true to prevent concurrent requests
      fetchMoreProducts(page + 1)
        .finally(() => setLoadingMore(false));  // Reset loadingMore on completion (success or failure)
    }
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function () {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, arguments), delay);
    };
  };

  const debouncedLoadMore = debounce(loadMore, 500);

  const fetchMoreProducts = async (newPage) => {
    try {
      const response = await productAPI.fetchPagedProducts({
        subCategoryName,
        subCategoryId,
        page: newPage,
      });

      if (response.data.data.length === 0) {
        setHasMore(false);
      } else {
        setProductsList((prevProducts) => {
          const uniqueProducts = [
            ...new Map([...prevProducts, ...response.data.data].map((item) => [item.id, item])).values(),
          ];
          setPage(newPage);
          return uniqueProducts;
        });
      }
    } catch (error) {
      console.log("Api error")
    }
  };


  const fetchProducts = async (page) => {
    try {
      SetShowLoadingCards(true)
      const response = await productAPI.fetchPagedProducts({ subCategoryName, subCategoryId, page });
      setProductsList(response.data.data);
      SetShowLoadingCards(false);
      if (response.data.data.length === 0) {
        setHasMore(false)
      }
    } catch (error) { }
  };

  useEffect(() => {
    fetchCategories();
    if (isLoggedIn) {
      fetchCartDetails();
      fetchFavouriteProducts();
    }
  }, [isLoggedIn]);

  const fetchFavouriteProducts = () => {
    dispatch(actionsCreator.FETCH_FAVOURITE_PRODUCTS());
  };

  const fetchCartDetails = () => {
    dispatch(actionsCreator.FETCH_CART_DETAILS());
  };

  return (
    <div>

      {/* Contains category name and the category list */}
      <br/>
      <div className="tab-container">

        <div className="category-name-desc">

          <p className="category-name">{categoryName}</p>
          <p className="category-desc">{categoryDescription}</p>

        </div>
        <div className="tab-wrapper">
          {
            categoryList ?
              categoryList.map((item, index) => {
                return (
                  <CategoryCard
                    active={item.name === categoryName}
                    title={item.name}
                    key={item.name}
                    image={item.image}
                    onClick={() => tabhandler(item.name)}
                    color={(index)}
                  />
                );
              }) :
              null
          }
        </div>
      </div>

      {/* subcategory list */}
      <div className="tab-wrapper">
        {
          categoryList &&
          categoryList.map((item, index) => (

            item.sub_categories.length > 0 && item.name === categoryName ?
              <SubCategoryCard
                active={item.name === subCategoryName}
                title={"All"}
                key={item.name}
                image={item.image}
                onClick={() => subtabhandler(item.name, item.id)}
                color={(index)}
              />
              :
              null
          ))
        }

        {/* subcategories list */}
        {
          categoryList &&
          categoryList.map((item) => (
            item.name === categoryName ?
              item.sub_categories.length > 0 && item.sub_categories.map((subitem, index) => {
                return (
                  <SubCategoryCard
                    active={subitem.name === subCategoryName}
                    title={subitem.name}
                    key={subitem.name}
                    image={subitem.image}
                    onClick={() => subtabhandler(subitem.name, subitem.id)}
                    color={(index)}
                  />
                );
              }) 
              : 
              null
          ))
        }

      </div>

      {/* product list */}
      <div className="tab-products">
        {
          productsList && productsList.length > 0 ?
            (
              <InfiniteScroll
                className="product-cards"
                loadMore={debouncedLoadMore}
                hasMore={hasMore}
                loader={<div className="make-inline"><LoadingProducts number={6} /></div>}
              >

                {
                  showLoadingCards ? (<LoadingProducts number={10} />)
                    :
                    <>
                      {
                        productsList.map((item) => {
                          return (
                            <ProductCard
                              title={item.product_name}
                              quantity={item.description}
                              price={item.price}
                              image={item.photo}
                              id={item.id}
                              description={item.description}
                              oldprice={item.market_price}
                              outofstock={item.out_of_stock}
                              quantity_remaining={item.quantity_remaining}
                            />
                          );
                        })
                      }

                      {
                        hasMore === false ? (
                          <div>
                            Products Finished...
                          </div>
                        ) :
                          (
                            <div>

                            </div>
                          )
                      }
                    </>
                }
              </InfiniteScroll>
            )
            :
            (
              hasMore ?
                <LoadingProducts number={10} /> :
                <p>Products Coming soon</p>
            )
        }
      </div>
    </div>
  );
};

export default Category;

