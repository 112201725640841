import React from 'react'
import { useSelector } from 'react-redux';
import { Addonbanner, TextHeading, Paragraph } from '../../Components'
import '../Terms/Terms.scss'

const mapStateToProps = ({ auth  }) => ({
  auth,
});

export default function Privacypolicy() {
  const { auth } = useSelector(mapStateToProps);

  return (
    <div>
        <Addonbanner heading="Useful Information" smallheading="Last updated: Febaury 22, 2022"/>
        
        <div className='addon-container'>
            <div className='addon-content'>
              {
                auth.tenantDetails?.template_details?.about_us &&
               <> 
              <TextHeading fontWeight="bold" heading="About Us" id="Aboutus"/>
              <Paragraph lineHeight="2rem" text={auth.tenantDetails?.template_details?.about_us}/>
              <br />
              </>
              }

               {
                auth.tenantDetails?.template_details?.terms_and_conditions &&
               <> 
                <TextHeading fontWeight="bold" heading="Terms & conditions" id="TermsConditions" />
                <Paragraph lineHeight="2rem" text={auth.tenantDetails?.template_details?.terms_and_conditions} />
                <br />
              </>
              }

               {
                auth.tenantDetails?.template_details?.delivery_services &&
               <> 
                <TextHeading fontWeight="bold" heading={`${auth.tenantDetails?.title} Delivery Services`} id="PhurtiDeliveryServices" />
                <Paragraph lineHeight="2rem" text={auth.tenantDetails?.template_details?.delivery_services}/>
                <br />
              </>
              }

              {
                auth.tenantDetails?.template_details?.payment_methods &&
               <> 
                <TextHeading fontWeight="bold" heading="Payment Methods" id="PaymentMethods"/>
                <Paragraph lineHeight="2rem" text={auth.tenantDetails?.template_details?.payment_methods} />
                <br />
              </>
              }

              {
                auth.tenantDetails?.template_details?.return_policy &&
               <> 
                <TextHeading fontWeight="bold" heading="Return Policy" id="ReturnPolicy" />
                <Paragraph lineHeight="2rem" text={auth.tenantDetails?.template_details?.return_policy} />
                <br />
              </>
              }

              {
                auth.tenantDetails?.template_details?.privacy_policy &&
               <> 
                <TextHeading fontWeight="bold" heading="Privacy Policy" id="PrivacyPolicy"/>
                <Paragraph lineHeight="2rem" text={auth.tenantDetails?.template_details?.privacy_policy} />
                <br />
              </>
              }

              {
                auth.tenantDetails?.template_details?.refund_policy &&
               <> 
                <TextHeading fontWeight="bold" heading="Refund Policy" id="RefundPolicy"/>
                <Paragraph lineHeight="2rem" text={auth.tenantDetails?.template_details?.refund_policy} />
                <br />
              </>
              }
                
                

                <br />

                {/* Contact Us Start  */}
                <TextHeading fontWeight="bold" heading="Contact Us" id="ContactUs"/>
                {/* <br /> */}

                <TextHeading fontWeight="bold" heading="Address"  fontSize=".8rem"/>
                <Paragraph lineHeight="2rem" text={`${auth.tenantDetails?.address}`} />
                
                <br />

                {
                  auth?.tenantDetails?.business_details &&  Object.entries(auth?.tenantDetails?.business_details).map(([key, values]) => {

                    let heading = '';
                    if(key === 'gst'){
                      heading = 'GST'
                    }else if(key == 'cin'){
                      heading = 'CIN'
                    }else if(key == 'phone_number'){
                      heading = 'Phone Number'
                    }else if(key == 'email'){
                      heading = 'Email'
                    }else{
                      heading = key;
                    }

                    return (
                      <>
                        <TextHeading fontWeight="bold" heading={heading}  fontSize=".8rem"/>
                        <Paragraph heading=" " fontWeight="bold" text={`${values || "+918861144646"}`} />
                        <br/>
                      </>
                    )
                  })
                }
                {/* Contact Us End  */}
            </div>
        </div>
        {/* <div className="addonCenter" id="ContactUs">
          <Paragraph text="For any further queries, drop us an email at" lineHeight="2rem"/>
          <a href="mailto:tech@phurti.in">
            <TextHeading fontWeight="bold" heading="team@phurti.in" id="PrivacyPolicy" fontSize="1.5rem"/> 
          </a>

        </div> */}
    </div>
  )
}
